import api from "./api";
import authHeader from "./auth-header";
import { toast } from "vue3-toastify";

class BaseService {
  constructor(API_URL, customToken = null) {
    this.API_URL = API_URL;
    if (customToken) {
      api.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${customToken}`;
        return config;
      });
    }
  }

  async getListData(params) {
    try {
      const { data } = await api.get(`${this.API_URL}`, {
        headers: authHeader(),
        params,
      });
      return data;
    } catch (error) {
      console.log("FAILED REQUEST DATA :", error);
      toast.error(error.message);
    }
  }

  async getData(id, params = {}) {
    try {
      const { data } = await api.get(`${this.API_URL}/${id}`, {
        headers: authHeader(),
        params,
      });
      return data;
    } catch (error) {
      console.log("FAILED REQUEST DATA :", error);
      toast.error(error.message);
    }
  }

  async createData(params) {
    try {
      const { data } = await api.post(`${this.API_URL}`, params, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED POST DATA :", error);
      toast.error(error.message);
      return error.message;
    }
  }

  async updateData(id, params) {
    try {
      const { data } = await api.put(`${this.API_URL}/${id}`, params, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED PUT DATA :", error);
      toast.error(error.message);
    }
  }

  async deleteData(id) {
    try {
      const { data } = await api.delete(`${this.API_URL}/${id}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED DELETE DATA :", error);
      toast.error(error.message);
    }
  }

  async uploadFile(formData) {
    try {
      const { data } = await api.post(`${this.API_URL}`, formData, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      console.log("FAILED UPLOAD FILE :", error);
      toast.error(error.message);
    }
  }

  async downloadFile(uuid) {
    try {
      const { data } = await api.get(`${this.API_URL}/${uuid}`, {
        headers: {
          ...authHeader(),
        },
        responseType: "blob",
      });
      return data;
    } catch (error) {
      console.log("FAILED DOWNLOAD FILE :", error);
      toast.error(error.message);
    }
  }
}

export default BaseService;
