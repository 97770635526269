<template>
  <div v-if="isloading" class="text-center">
    <H1>Loading...</H1>
  </div>
  <div v-else class="post d-flex flex-column-fluid" id="kt_post">
    <div v-if="token" id="kt_content_container" class="container-xxl py-8">
      <div class="">
        <h3 class="m-0 title-green">
          <span class="kt-font-transform-u">
            {{ $route.query.type.toUpperCase() }}
          </span>
          Aktif
        </h3>
        <hr class="m-0 title-green horizontal-line" />
        <h4 class="title-green">
          {{ historyData.kta.no_kta }}
        </h4>
        <div class="text-center py-5">
          <img class="w-200px" :src="historyData.kta.img" alt="" srcset="" />
        </div>
      </div>

      <div class="">
        <h3 class="m-0 title-green">Riwayat Pengajuan</h3>
        <hr class="m-0 title-green horizontal-line" />
        <HistoryStep :historyData="historyData.history" />
      </div>

      <div class="d-grid gap-4">
        <button
          type="button"
          @click="toSubmission"
          class="btn btn-primary btn-lg btn-block"
        >
          Buat Pengajuan
        </button>
        <button
          @click="$router.back()"
          class="btn btn-secondary btn-lg btn-block"
        >
          Kembali
        </button>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-danger mb-10" role="alert">
        Akses expired, silahkan kembali ke beranda terlebih dahulu
      </div>
    </div>
  </div>
</template>
<script>
import BaseService from "@/services/base.service";
import HistoryStep from "../../../components/mobile/HistoryStep.vue";

export default {
  components: {
    HistoryStep,
  },
  data() {
    return {
      isloading: true,
      type: null,
      token: null,
      historyData: null,
    };
  },
  methods: {
    async init() {
      try {
        const { type, token } = this.$route.params;
        const personData = new BaseService("services/history", token);
        const { data } = await personData.getListData();
        this.type = type;
        this.token = token;
        this.historyData = data;
        this.isloading = false;
      } catch (error) {
        this.isloading = false;
        console.log(error);
      } finally {
        this.isloading = false;
        console.log("ok");
      }
    },
    toSubmission() {
      const { token } = this.$route.params;
      this.$router.push({
        name: "service-submission",
        params: { token },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.btn-primary {
  background-color: #325b3b !important;
}
</style>
