<template>
    <div>
        <div class="my-4 history-step">
            <div class="p-0 step" v-for="(history, index) in historyData" :key="index">
                <div class="stepper">
                    <div class="stepper-circle"></div>
                    <div class="stepper-line"></div>
                </div>
                <div class="stepper-content">
                    <p class="m-0 h5">
                        <strong>
                            Pengajuan Tanggal {{ history.timestamp }}
                        </strong>
                    </p>
                    <div class="text-gray">
                        Status: <span class="fw-bold"> {{ history.status }} </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'historyData'
    ],

}

</script>

<style>
.text-gray {
    color: #858585;
}

.step {

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    background-color: cream;
}

.stepper {
    position: relative;
}

.step .stepper-circle {
    background-color: #315b3b;
    border: none;
    border-radius: 100%;
    width: 10px;
    /* +6 for border */
    height: 10px;
    display: inline-block;
}

.step .stepper-line {
    top: 13px;
    left: 4px;
    /* height: 120px; */
    /* height: 100%; */
    bottom: -5px;

    position: absolute;
    border-left: 2px solid #77927c;
}

.stepper-content {
    margin-left: 10px;
    margin-bottom: 15px;
    display: inline-block;
}

.step:last-child .stepper-line {
    border-left: none;
    z-index: -1;
    /* behind the circle to completely hide */
}
</style>


.container {
width: 25%;
}



.v-stepper {
position: relative;
/* visibility: visible; */
}


/* regular step */




.step.completed .circle {
visibility: visible;
background-color: rgb(6,150,215);
border-color: rgb(6,150,215);
}

.step.completed .line {
border-left: 3px solid rgb(6,150,215);
}

.step.active .circle {
visibility: visible;
border-color: rgb(6,150,215);
}

.step.empty .circle {
visibility: hidden;
}

.step.empty .line {
/* visibility: hidden; */
/* height: 150%; */
top: 0;
height: 150%;
}





/* codepen override */
html *
{
font-size: 15px !important;
color: #000 !important;
font-family: Arial !important;
}